import React, {useCallback, useContext, useState} from 'react';
import {
   Button,
   Grid,
   LinearProgress,
   TextField, Tab, makeStyles, Box, InputAdornment, IconButton, useTheme, useMediaQuery
} from '@material-ui/core';
import { TabContext, TabList} from "@material-ui/lab";
import {SearchContext} from "../../lib/contexts";
import SearchResults from "../SearchResults";
import {
   Business, Close,
   EventSeat,
   LibraryBooks,
   OndemandVideo,
   Place, Public, Search,
   Settings,
   ShoppingBasket
} from "@material-ui/icons";
import SettingsMenu from "../SettingsMenu";

const useStyles = makeStyles(theme => ({
   tabPanel:{
      margin:'25px 0px 0px',
      padding:0,
      width: '100%'
   },
   loading:{width:'100%',margin:50, color:'inherit'},
   icon:{
      display:'inline',
      '& svg':{
         marginBottom:-5,
         marginRight:5
      }
   }
}));
const SearchFormBox = () => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   const {
      state: { query, resultsLoading, type, showExtendedCategories },
      actions: { setQuery, setType, forceSearch },
   } = useContext(SearchContext);
   
   const [menuAnchor, setMenuAnchor] = useState(null);
   const toggleMenu = useCallback((event) => {
      setMenuAnchor(menuAnchor ? null : event.currentTarget);
   },[menuAnchor]);

   const a11yProps = (index, active=true) => {
      return {
         value: String(index),
         id: `scrollable-tab-${index}`,
         'aria-controls': `tab-${index}`,
      };
   }
   const handleTabChange = useCallback((event, value)=>{
      setType(value);
   },[setType])
   
   return (
      <Grid
         container
         direction="row"
         justifyContent="space-evenly"
         alignItems="flex-start"
         spacing={2}
      >
         <Grid item xs={12}>
            <TextField
               fullWidth
               value={query}
               onChange={event=>setQuery(event.target.value)}
               label="Seek and find..."
               margin="normal"
               variant="outlined"
               InputProps={{
                  endAdornment: <InputAdornment position="end">
                     {query && <IconButton
                        aria-label="clear"
                        onClick={()=>setQuery('')}
                     >
                        <Close/>
                     </IconButton>}
                     <IconButton
                        aria-label="search"
                        onClick={forceSearch}
                     >
                        <Search />
                     </IconButton>
                  </InputAdornment>}}
            />
         </Grid>
         <Grid item xs={12} container
               spacing={0}
               direction="column"
               alignItems="center"
               justifyContent="center"
               style={{textAlign:'center', marginLeft: 0}}>
            <TabContext value={type}>
               <Grid item xs={12} container justifyContent={'space-between'}>
                  <Grid item xs={11}>
                     {
                        showExtendedCategories ?
                           <TabList
                              style={{width:'100%'}}
                              value={type}
                              onChange={handleTabChange}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                              scrollButtons="auto"
                              aria-label="search categories"
                           >
                              <Tab label={<span className={classes.icon}><Public fontSize={"small"}/>&nbsp;All</span>} {...a11yProps('')} />
                              <Tab label={<span className={classes.icon}><LibraryBooks fontSize={"small"}/>&nbsp;News</span>} {...a11yProps('news')} />
                              <Tab label={<span className={classes.icon}><OndemandVideo fontSize={"small"} />&nbsp;Videos</span>} {...a11yProps('videos')} />
                              <Tab label={<span className={classes.icon}><ShoppingBasket fontSize={"small"} />&nbsp;Shopping</span>} {...a11yProps('shopping')} />
   
                              {/*<Tab label={<span className={classes.icon}><PhotoLibrary fontSize={"small"} />&nbsp;Creative Works</span>} {...a11yProps('media')} />*/}
                              <Tab label={<span className={classes.icon}><Place fontSize={"small"} />&nbsp;Places</span>} {...a11yProps('places')} />
                              <Tab label={<span className={classes.icon}><EventSeat fontSize={"small"} />&nbsp;Events</span>} {...a11yProps('events')} />
                              <Tab label={<span className={classes.icon}><Business fontSize={"small"} />&nbsp;Companies</span>} {...a11yProps('companies')} />
                           </TabList>
                           :
                           <TabList
                              style={{width:'100%'}}
                              value={type}
                              onChange={handleTabChange}
                              indicatorColor="primary"
                              textColor="primary"
                              centered={matchSM ? false : true}
                              variant={matchSM ? "scrollable" : "standard"}
                              scrollButtons={"auto"}
                              aria-label="search categories"
                           >
                              <Tab label={<span className={classes.icon}><Public fontSize={"small"}/>&nbsp;All</span>} {...a11yProps('')} />
                              <Tab label={<span className={classes.icon}><LibraryBooks fontSize={"small"}/>&nbsp;News</span>} {...a11yProps('news')} />
                              <Tab label={<span className={classes.icon}><OndemandVideo fontSize={"small"} />&nbsp;Videos</span>} {...a11yProps('videos')} />
                              <Tab label={<span className={classes.icon}><ShoppingBasket fontSize={"small"} />&nbsp;Shopping</span>} {...a11yProps('shopping')} />
                           </TabList>
                     }
                  </Grid>
                  <Grid item xs={1} style={{ position:'relative',marginTop:5, left: matchSM ? -15 : 0}}>
                     <Button onClick={toggleMenu}><Settings/></Button>
                     {menuAnchor && <SettingsMenu menuAnchor={menuAnchor} toggleMenu={toggleMenu}/>}
                  </Grid>
               </Grid>
               
               
               <Grid
                  container
                  item
                  xs={12}
               >
                  <Box className={classes.tabPanel}>
                     <Grid
                        container
                        item
                        xs={12}
                        justifyContent={'space-between'}
                        spacing={1}
                     >
                        { resultsLoading ? <LinearProgress color="secondary" className={classes.loading} /> : <SearchResults/> }
                     </Grid>
                  </Box>
               </Grid>
            </TabContext>
         </Grid>
      </Grid>
   );
};

export default SearchFormBox;