import { lighten } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { opacity } from './utils';

const common = {
   black: '#0b0b0c',
   white: '#ffffff',
   red: '#ff0000',
   host: '#38b642',
   increase: '#BEF2D7',
   decrease: '#F9CDBA',
   border: 'rgba(0,0,0,0.28)',
   grey: {
      50: '#FAFAFC',
      100: '#F5F5F7',
      200: '#EEEEF2',
      300: '#DEDEE2',
      400: '#BDBDC2',
      500: '#9E9EA8',
      600: '#757575',
      700: '#757575',
      800: '#424247',
      900: '#212126',
   },
};
const palette = (primaryColor, secondaryColor) => {
   return {
      common: common,
      primary: {
         main: primaryColor || '#2972e0',
         title: '#638EAE',//'#17528d',
         host: '#52936A',
         dark: '#2972e0',
         light: '#2972e0',
         contrastText: '#ffffff',
         background: lighten('#536dfe', 0.92),
         backgroundHover: lighten('#536dfe', 0.5),
         border: '#2972e0',
      },
      secondary: {
         main: secondaryColor || '#f15f71',
         dark: '#2972e0',
         light: '#2972e0',
         contrastText: '#ffffff',
         background: lighten('#536dfe', 0.92),
         backgroundHover: lighten('#536dfe', 0.5),
         border: '#2972e0',
      },
      error: {
         main: '#DA4045',
         dark: '#A74145',
         light: '#EBB1B3',
         contrastText: '#ffffff',
         background: lighten('#DA4045', 0.92),
         backgroundHover: lighten('#DA4045', 0.5),
         border: '#A74145',
      },
      warning: {
         main: '#f8ab3a',
         dark: '#c38147',
         light: '#ffc960',
         contrastText: '#ffffff',
         background: lighten('#f8ab3a', 0.92),
         backgroundHover: lighten('#f8ab3a', 0.5),
         border: '#c38147',
      },
      success: {
         main: '#45ae57',
         dark: '#3d954c',
         light: '#60ba6f',
         contrastText: '#ffffff',
         background: lighten('#68b76b', 0.92),
         backgroundHover: lighten('#68b76b', 0.5),
         border: '#3d954c',
      },
      info: {
         main: '#536dfe',
         dark: '#25448f',
         light: '#9fa8da',
         contrastText: '#ffffff',
         background: lighten('#536dfe', 0.92),
         backgroundHover: lighten('#536dfe', 0.5),
         border: '#25448f',
      },
      background: {
         paper: '#ffffff',
         default: '#fafafa',
      },
   };
};

const theme = (primaryColor, secondaryColor) => {
   const finalPalette = palette(primaryColor, secondaryColor);
   
   return createTheme({
      themeName: 'Light',
      palette: finalPalette,
      globalClasses:{
         screen:{
            width:'96%',
            margin:'10px auto auto',
         },
      },
      typography: {
         useNextVariants: true,
         htmlFontSize: 16,
         fontFamily: '"SharpBook19", "Helvetica", "Arial", sans-serif',
         fontSize: 14,
         fontWeightLight: 400,
         fontWeightRegular: 400,
         fontWeightMedium: 400,
         fontWeightBold: 400,
         h1: {},
         h2: {
            fontWeight: 600,
         },
         h3: {},
         h4: {},
         h5: {},
         h6: {},
         body1: {
         },
         body2: {
         },
         subtitle1: {
         },
         subtitle2: {},
         helper: {
            primary: finalPalette.common.grey['100'],
            secondary: opacity(finalPalette.common.grey['100'], 0.54),
            light: '#DFE9E9',
         },
      },
      overrides: {
         MuiSelect:{
            root: {
               color: '#111',
               '& *':{
                  color: '#111',
               }
            }
         },
         MuiTypography:{
            root: {
               color: '#111',
               '& *':{
                  color: '#111',
               }
            },
         },
         MuiTextField:{
            root: {
               color: '#111',
               '& *':{
                  color: '#111',
               }
            },
         },
         MuiButton: {
            root: {},
            contained: {},
            outlined: {},
            text: {},
            sizeSmall: {},
            sizeLarge: {},
         },
         MuiCard: {
            root: {
               color: '#111',
               '& *':{
                  color: '#111',
               }
            }
         },
      },
      breakpoints: {
         values: {
            xs: 320,
            sm: 641,
            md: 960,
            lg: 1080,
            xl: 1920,
         },
      },
   });
};
export default theme;
