import React, {useCallback, useContext} from 'react';
import {
   Box,
   Card,
   CardContent,
   LinearProgress,
   makeStyles,
   Tooltip,
   Typography,
   useMediaQuery,
   useTheme
} from '@material-ui/core';
import {Info} from "@material-ui/icons";
import {SearchContext} from "../../lib/contexts";
const useStyles = makeStyles({
   twit: {
      marginTop:20,
      minWidth: 200
   },
   title: {
      fontSize: 14,
   },
   trendingItem: {
      display:'block',
      textDecoration:'none',
      color:'inherit',
      fontSize: 17,
      textAlign: 'left',
      margin: '10px 3px 10px'
   },
   infoIcon:{
      float:'right',
      fontSize: 17,
      marginBottom: -3
   },
   twitButton: {
      textAlign: 'center',
      margin: '5px auto auto'
   },
   twitLink: {
      textDecoration: 'none'
   },
   scrollBox:{
      maxHeight: 113,
      overflowY: 'scroll'
   },
   loadingBar: {
      width:'80%',
      margin:'40px auto 30px'
   },
});

const TrendingBox = () => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   const {
      state: { trendingList },
      actions: { forceSearch, setQuery },
   } = useContext(SearchContext);
   
   const handleSelection = useCallback((event,value)=>{
      event.preventDefault();
      setQuery(value);
      forceSearch();
   },[forceSearch,setQuery])
   return(
<Card className={classes.twit}>
      <CardContent>
         <Typography className={classes.title} gutterBottom>
            Trending Searches&nbsp;<Tooltip title={'Trending keywords over the last few days.'}><Info className={classes.infoIcon}/></Tooltip>
         </Typography>
            {
               trendingList ?
                  <Box className={matchSM ? classes.scrollBox : ''}>
                     {trendingList.map((item, index)=>{
                        return <a key={index} href={"?q="+encodeURI(item.term)} onClick={event=>handleSelection(event,item.term)} className={classes.trendingItem} color="textSecondary">#{index+1}&nbsp;{item.term}</a>
                     })}
                  </Box>
                   :
                  <LinearProgress color="secondary" className={classes.loadingBar} />
            }
      </CardContent>
   </Card>
)
};
export default TrendingBox;