import React, {useCallback, useContext, useMemo} from 'react';
import {Typography} from "@material-ui/core";
import {SearchContext} from "../../lib/contexts";
import quotes from "../../lib/constants/quotes.json"
const RandomQuote = () => {
   const {
      state: { resultsLoading, searchResults },
      actions: { forceSearch, setQuery },
   } = useContext(SearchContext);
   const quotesList = useMemo(()=> quotes ? quotes.quotes : [], []);
   // eslint-disable-next-line
   const quote = useMemo(()=>quotes ? quotesList[Math.floor(Math.random()*quotesList.length)] : '', [searchResults]);
   const handleSelection = useCallback((event,value)=>{
      event.preventDefault();
      setQuery(value);
      forceSearch();
   },[forceSearch, setQuery])
   return(<>{
      !resultsLoading && !searchResults && quote && <>
         <Typography color="textSecondary" gutterBottom style={{textAlign:'center',margin:'150px auto 0px',color: '#fafafa'}}>
            "{quote.quote}"
         </Typography>
         <Typography color="textSecondary" gutterBottom style={{textAlign:'center',margin:'10px auto 150px',color: '#fafafa'}}>
            <a href={"?q="+encodeURI(quote.author)} onClick={event=>handleSelection(event,quote.author)}  style={{color: '#fafafa', textDecoration:'none', borderWidth:0, borderBottomWidth:1, borderStyle:'solid', borderColor: '#fafafa'}}>{quote.author}</a>
         </Typography>
      </>
      }</>)
};
export default RandomQuote;