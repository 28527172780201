import React, {useContext, useEffect} from 'react';
import {FormControlLabel, Switch, Typography} from "@material-ui/core";
import {Brightness4, Brightness6} from "@material-ui/icons";
import {ThemeContext} from "../../lib/contexts";
import {getUrlParam} from "../../lib/constants/helpers";
const DarkModeToggle = () => {
   const { darkMode, toggleTheme } = useContext(ThemeContext);
   const desiredTheme = getUrlParam('t');
   
   useEffect(()=>{
      if (desiredTheme === 'light') {
      if (darkMode) {
         toggleTheme()
      }
      }
      if (desiredTheme === 'dark') {
      if (!darkMode) {
         toggleTheme()
      }
      }
   },[desiredTheme, darkMode, toggleTheme])
   
   return(<Typography color="textSecondary" gutterBottom style={{flex:1,width:69,marginRight:10, float:'right'}}>
      <FormControlLabel labelPlacement={'start'} control={<>{darkMode ? <Brightness6/> : <Brightness4/>}&nbsp;<Switch checked={darkMode} size={'small'} onChange={toggleTheme}/></>} label={""} />
   </Typography>)
};
export default DarkModeToggle;