export const ENVIRONMENT = [
  ['production', window.location.origin.includes('capacitor://localhost')],
  ['development', !window.location.origin.includes('capacitor://localhost') && !window.location.origin.includes('openindex.app')],
  ['staging', window.location.origin.includes('beta.openindex.app')],
  ['production', !window.location.origin.includes('beta.openindex.app') && window.location.origin.includes('openindex.app')]
].find(([name, isActive]) => isActive)[0]

export const CLIENT_HOST = {
   development: `http://localhost:3000`,
   staging: `https://beta.openindex.app`,
   production: `https://openindex.app`
}[ENVIRONMENT]

export const API_HOST = {
  development: 'http://localhost:5002/api/v1',
  staging: 'https://openindex.herokuapp.com/api/v1',
  production: 'https://openindex.herokuapp.com/api/v1'
}[ENVIRONMENT]

export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: null,
  production: null
}[ENVIRONMENT]
